import React, { useEffect } from 'react';
import '../../containers/containerStyles.css';
import MetricBox from '../../components/UI/MetricBox';
import { Switch } from 'antd';
import { useState } from 'react';
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetBoxMetricsQuery } from '../../redux/api/genericReporting';
import {Spin} from 'antd';

function ExecutiveSummaryBoxMetrics() {

    const dispatch = useDispatch();

    const [switchState, setSwitchState] = useState(false);
    const [compareStartDate, setCompareStartDate] = useState(null);
    const [compareEndDate, setCompareEndDate] = useState(null);
    const [compareStartDateStr, setCompareStartDateStr] = useState(null);
    const [compareEndDateStr, setCompareEndDateStr] = useState(null);
    const [boxElements, setBoxElements] = useState([]);

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    const boxMetricsData = useGetBoxMetricsQuery({
        client: selectedClient,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        compareStartDate: compareStartDateStr,
        compareEndDate: compareEndDateStr
    });

    useEffect(() => {
        // calculate how many days are in the selected range
        if (selectedStartDate && selectedEndDate) {
            const tmp_startDate = dayjs(selectedStartDate);
            const tmp_endDate = dayjs(selectedEndDate);

            const daysInSelectedRange = tmp_endDate.diff(tmp_startDate, 'days');

            // create a new start date that is the same number of days before the selected start date
            const newCompareStartDate = dayjs(tmp_startDate.subtract(daysInSelectedRange + 1, 'days').format('YYYY-MM-DD'));
            setCompareStartDate(newCompareStartDate);

            // create a new end date that is the same number of days before the selected end date
            const newCompareEndDate = dayjs(tmp_endDate.subtract(daysInSelectedRange + 1, 'days').format('YYYY-MM-DD'));
            setCompareEndDate(newCompareEndDate);

            // set the string versions of the dates
            setCompareStartDateStr(newCompareStartDate.format('YYYY-MM-DD'));
            setCompareEndDateStr(newCompareEndDate.format('YYYY-MM-DD'));
        }
    }, [selectedClient, selectedStartDate, selectedEndDate, switchState]);

    useEffect(() => {
        if (boxMetricsData.status === 'fulfilled') {
            const boxData = boxMetricsData.data;
            const newBoxElements = boxData.map((box, index) => {
                return (
                    <MetricBox title={box.title} value={box.value} popValue={(box.pop * 100).toFixed(2)} isInverted={box.is_inverted} />
                );
            });
            setBoxElements(newBoxElements);
        }
        else if (boxMetricsData.status === 'rejected') {
            setBoxElements([
                <div>There was an error fetching the data</div>
            ]);
        }
        else {
            setBoxElements([
                <Spin></Spin>
            ]);
        }
    }, [boxMetricsData]);

    return (
        <div>
            <hr></hr>
            <div style={{
                'marginBottom': '0rem',
                'marginTop': '0rem',
                'display': 'flex',
                'flexDirection': 'row',
                'marginLeft': '70vw'
            }} >
                <h3 style={{
                    'marginTop': '0rem'
                }}>Manual Compare Range</h3>
                <Switch style={{
                    'marginLeft': '1rem', 'marginTop': '0.8vh'
                }} checked={switchState} onChange={setSwitchState}></Switch>

                {switchState ? <div style={{
                    'marginLeft': '1rem'
                }}>
                    <DatePicker.RangePicker value={
                    [compareStartDate, compareEndDate]
                }
                        onChange={(dates) => {
                            setCompareStartDate(dates[0]);
                            setCompareEndDate(dates[1]);
                            setCompareStartDateStr(dates[0].format('YYYY-MM-DD'));
                            setCompareEndDateStr(dates[1].format('YYYY-MM-DD'));
                        }
                        }
                    >
                    </DatePicker.RangePicker></div> : <div></div>}
            </div>
        <div>
            <div style={{
            'display': 'flex',
            'flexDirection': 'row',
            'justifyContent': 'center',
        }}>
            {boxElements}
            </div>
            </div>
        </div>
    );
}

export default ExecutiveSummaryBoxMetrics;