import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
    selectedClient: "",
    startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
    countryFilter: "All",
    gaDeviceCategoriesFilter: "All",
    gaSourceFilter: "All",
    gaLandingPageFilter: "All",
    dateGranularity: "day",
    selectedConfig: null
};

const genericReportingSlice = createSlice({
    name: 'genericReporting',
    initialState,
    reducers: {
        setSelectedClient: (state, value) => {
            state.selectedClient = value.payload;
        },
        setStartAndEndDate: (state, value) => {
            console.log('value is')
            console.log(value.payload)
            state.startDate = value.payload[0];
            state.endDate = value.payload[1];
        },
        setCountryFilter: (state, value) => {
            state.countryFilter = value.payload;
        },
        setGaDeviceCategoriesFilter: (state, value) => {
            state.gaDeviceCategoriesFilter = value.payload;
        },
        setGaSourceFilter: (state, value) => {
            state.gaSourceFilter = value.payload;
        },
        setGaLandingPageFilter: (state, value) => {
            state.gaLandingPageFilter = value.payload;
        },
        setDateGranularity: (state, value) => {
            state.dateGranularity = value.payload;
        },
        setSelectedConfig: (state, value) => {
            state.selectedConfig = value.payload;
        }
    }
});

export const { setSelectedClient, setStartAndEndDate, 
    setCountryFilter, setGaDeviceCategoriesFilter, setGaSourceFilter,
    setDateGranularity, setSelectedConfig, setGaLandingPageFilter
 } = genericReportingSlice.actions;

export default genericReportingSlice.reducer;